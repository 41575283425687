import {
  createRootRoute,
  createRoute,
  LinkOptions,
  Navigate,
  Router,
  useNavigate,
} from '@tanstack/react-router'
import { actionPlanTasksQuerySchema } from '@valuecase/common'
import { Permission } from '@valuecase/seller-backend'
import { useEffect } from 'react'
import { z } from 'zod'
import { useHasPermission } from '../../auth/permissions'
import ActivityFeed from '../activityFeed/ActivityFeed'
import Analytics from '../analytics/Analytics'
import TaskAnalytics from '../analytics/TaskAnalytics'
import { AssetLibrary } from '../assetLibrary/AssetLibrary'
import CompanySettings from '../companySettings/CompanySettings'
import ImprintAnPrivacy from '../imprint&privacy/ImprintAnPrivacy'
import Integrations from '../integrations/Integrations'
import MailNotifications from '../mailNotifications/MailNotifications'
import ProfileAccount from '../profileAccount/ProfileAccount'
import SpaceProperties from '../spaceProperties/SpaceProperties'
import EmbeddedSpaceCreate from '../spaces/EmbeddedSpaceCreate'
import { SpaceFilterProvider } from '../spaces/hooks/useSpaceFiltering'
import Spaces from '../spaces/Spaces'
import { TaskDashboard } from '../tasks/TaskDashboard'
import { TaskList } from '../tasks/TaskList'
import { taskAnalyticsSearchSchema } from '@valuecase/common'
import { TasksLayout } from '../tasks/TasksContext'
import TeamsManagement from '../teams/TeamsManagement'
import Templates from '../templates/Templates'
import TenantsAdmin from '../tenantManagement/TenantsAdmin'
import UserManagement from '../userManagement/UserManagement'
import Layout from './layout/Layout'

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}
type RouteGuardProps = {
  necessaryPermission: Permission
  Component: () => JSX.Element
  onDeniedRedirectTo?: LinkOptions['to']
}
//RouteGuard redirects useres to Dashboard, currently Spaces if permissions are not sufficient to access Route
function RouteGuard({
  necessaryPermission,
  Component,
  onDeniedRedirectTo,
}: RouteGuardProps): JSX.Element {
  const permissionGranted = useHasPermission(necessaryPermission)
  const nav = useNavigate()

  useEffect(() => {
    if (!permissionGranted) {
      nav({ to: onDeniedRedirectTo || '/' })
    }
  }, [permissionGranted, nav, onDeniedRedirectTo])

  if (permissionGranted) {
    return <Component />
  }

  return <>Redirecting...</>
}

const rootRoute = createRootRoute()

// Renders a standalone space creation dialog that is not part of the main layout
const embedSpacesCreateRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: '/embed/spaces/create',
  component: EmbeddedSpaceCreate,
})

const dashboardRoute = createRoute({
  getParentRoute: () => rootRoute,
  id: 'dashboard',
  component: Layout,
})
const homeRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/',
  component: () => <Navigate to='/spaces' />,
})
export const spacesRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/spaces',
  validateSearch: z
    .object({
      createSpaceFromTemplate: z.coerce.string().optional(),
    })
    .optional()
    .default({}),
  component: Spaces,
})
export const templatesRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/templates',
  validateSearch: z
    .object({
      createTemplateFrom: z.coerce.string().optional(),
    })
    .optional()
    .default({}),
  component: () => <RouteGuard necessaryPermission={'TEMPLATES_MANAGE'} Component={Templates} />,
})
const assetLibraryRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/assetLibrary',
  component: () => (
    <RouteGuard necessaryPermission={'ASSET_LIBRARY_VIEW'} Component={AssetLibrary} />
  ),
})
const activityFeedRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/activityFeed',
  component: ActivityFeed,
})
const analyticsRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/analytics/teamActivityAnalytics',
  component: Analytics,
})
const taskAnalyticsLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'taskAnalytics',
  component: TasksLayout,
})
export const taskAnalyticsRoute = createRoute({
  getParentRoute: () => taskAnalyticsLayoutRoute,
  path: 'analytics/actionPlanAnalytics',
  validateSearch: taskAnalyticsSearchSchema,
  component: () => (
    <SpaceFilterProvider prefix='apa_' enableStorage={false}>
      <TaskAnalytics />
    </SpaceFilterProvider>
  ),
})
const tasksLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'tasks',
  component: TasksLayout,
})
const taskDashboardRoute = createRoute({
  getParentRoute: () => tasksLayoutRoute,
  path: 'tasks',
  component: TaskDashboard,
})
export const taskListRoute = createRoute({
  getParentRoute: () => tasksLayoutRoute,
  path: 'tasks/list',
  component: TaskList,
  validateSearch: z
    .object({
      taskStatus: z.preprocess(
        (value) => {
          if (!value) {
            return undefined
          }
          if (actionPlanTasksQuerySchema.shape.taskStatus.unwrap().options.includes(value as any)) {
            return value
          }
          return undefined
        },
        z.union([actionPlanTasksQuerySchema.shape.taskStatus, z.literal('all')]),
      ),
    })
    .optional()
    .default({}),
})
const mySettingsLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'mySettings',
})
const profileAccountRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/profileAccount',
  component: ProfileAccount,
})
const mailNotificationsRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/mailNotifications',
  component: MailNotifications,
})
const integrationsRoute = createRoute({
  getParentRoute: () => mySettingsLayoutRoute,
  path: 'mySettings/integrations',
  component: Integrations,
})

const administrationLayoutRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  id: 'administration',
})
const companySettingsRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/companySettings',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={CompanySettings}
      onDeniedRedirectTo='/spaces'
    />
  ),
})
const teamteamManagementRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/teamManagement',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={TeamsManagement}
      onDeniedRedirectTo='/spaces'
    />
  ),
})
const userManagementRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/userManagement',
  component: () => (
    <RouteGuard
      necessaryPermission={'USERS_MANAGE'}
      Component={UserManagement}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const spacePropertiesRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/spaceProperties',
  component: () => (
    <RouteGuard
      necessaryPermission={'SPACE_PROPERTIES_MANAGE'}
      Component={SpaceProperties}
      onDeniedRedirectTo='/spaces'
    />
  ),
})
const legacySpacePropertiesRoute = createRoute({
  getParentRoute: () => administrationLayoutRoute,
  path: '/administration/spaceProperties',
  component: () => (
    <RouteGuard
      necessaryPermission={'TENANT_SETTINGS_MANAGE'}
      Component={SpaceProperties}
      onDeniedRedirectTo='/spaces'
    />
  ),
})

const imprintAndPrivacyRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/imprintAndPrivacy',
  component: ImprintAnPrivacy,
})
const tenantsAdminRoute = createRoute({
  getParentRoute: () => dashboardRoute,
  path: '/tenants-admin',
  component: () => (
    <RouteGuard necessaryPermission={'ALL_TENANTS_ADMIN'} Component={TenantsAdmin} />
  ),
})

const routeTree = rootRoute.addChildren([
  dashboardRoute.addChildren([
    homeRoute,
    spacesRoute,
    templatesRoute,
    tasksLayoutRoute.addChildren([taskDashboardRoute, taskListRoute]),
    assetLibraryRoute,
    activityFeedRoute,
    analyticsRoute,
    taskAnalyticsLayoutRoute.addChildren([taskAnalyticsRoute]),
    mySettingsLayoutRoute.addChildren([
      profileAccountRoute,
      mailNotificationsRoute,
      integrationsRoute,
    ]),
    administrationLayoutRoute.addChildren([
      companySettingsRoute,
      userManagementRoute,
      teamteamManagementRoute,
      legacySpacePropertiesRoute,
    ]),
    tenantsAdminRoute,
    imprintAndPrivacyRoute,
    spacePropertiesRoute,
  ]),
  embedSpacesCreateRoute,
])

// Set up a ReactRouter instance
export const router = new Router({
  routeTree,
  defaultPreload: 'intent',
})
